import React, { useEffect, useState } from 'react';
import { Router } from '@reach/router';
import { useSelector } from 'react-redux';

import { PremiumRoute, ProRedirect } from '../components/router';
import { ContenidoProLayout } from '../layouts';

import { api } from '../services';

const ContenidoPro = () => {
  const [premiumData, setPremiumData] = useState(null);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [premiumHasExpired, setPremiumHasExpired] = useState(false);
  const [errorPremiumMessage, setErrorPremiumMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const currentLang = useSelector(({ config }) => config.language);
  const userStore = useSelector(store => store.login);
  const { fetchPremiumMenuContentData, fetchErrorMessagePremiumData } = api();

  useEffect(() => {
    let ignore = false;
    const fetchData = async ignore => {
      try {
        if (userStore && userStore.isLogged) {
          if (!ignore) {
            const premiumResponse = await fetchPremiumMenuContentData(
              currentLang,
              userStore.token
            );
            setPremiumData(premiumResponse.listPro);
            console.debug('premiumData', premiumData);
            setTitle(premiumResponse.title);
            setDescription(premiumResponse.description);
          }
        }
      } catch (e) {
        if (e === 'Unauthorized') {
          const response = await fetchErrorMessagePremiumData(currentLang);
          setErrorPremiumMessage(response);
          setPremiumHasExpired(true);
        } else {
          setError(e.message);
          setLoading(false);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData(ignore);
    return () => {
      ignore = true;
    };
  }, [currentLang]);

  return (
    <Router basepath="/contenido-pro">
      <ProRedirect path="/" />
      <ProRedirect path="/category/" />
      <PremiumRoute
        path="/category/:categoria"
        component={ContenidoProLayout}
        data={premiumData}
        title={title}
        description={description}
        loading={loading}
        currentLang={currentLang}
        error={error}
      />
    </Router>
  );
};

export default ContenidoPro;
